<template>
  <v-card class="mx-auto pa-3" max-width="700" v-if="loaded">
    <v-card-title>Select active event</v-card-title>
    <v-radio-group v-model="selectedActive">
      <v-radio v-for="e in events" :key="e" :value="e" :label="`${e}`"></v-radio>
    </v-radio-group>
    <v-btn class="primary ma-2" @click="aktivanEvent()" v-if="dispSubmit"> <v-icon>mdi-content-save</v-icon> Save active event</v-btn>
    <v-alert class="my-2" type="success" v-if="!dispSubmit">Active event has been updated!</v-alert>
  </v-card>
</template>

<script>
import db from "../firebaseInit.js";

export default {
  data() {
    return {
      selectedActive: "",
      dispSubmit: true,
      loaded: false,
      events: [],
    };
  },
  methods: {
    async aktivanEvent() {
      await db.collection("activeEvent").doc("eventTitle").update({ eventName: this.selectedActive });
      this.dispSubmit = false;
    },
  },
  async created() {
    db.collection("eventList")
      .get()
      .then((qS) => {
        qS.forEach((doc) => {
          this.events.push(doc.id);
        });
      });
    this.loaded = true;
  },
};
</script>
